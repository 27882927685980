/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useThemeProps } from '@findep/microfronts-core'
import { Grid, Typography, Box ,Tooltip,Paper,Backdrop} from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {  InfoOutlined } from '@material-ui/icons'

import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

import IconEmail from '../../assets/viewtube/icon_mail.svg'
import IconBuro from '../../assets/viewtube/icono_buro-credito.svg'
import IconCredit from '../../assets/viewtube/icono_evaluacion-credito.svg'
import IconPhone from '../../assets/viewtube/icono_telefono.svg'
import IconPerson from '../../assets/viewtube/icono_identidad.svg'


import IconCompleted from '../../assets/viewtube/icon_completed.svg'
import IconProgress from '../../assets/viewtube/icon_progress.svg'
import IconPending from '../../assets/viewtube/icon_pending.svg'
import IconError from '../../assets/viewtube/icon_error.svg'

const CardProgres = (inProps) => {
  const props = useThemeProps({ props: inProps, name: 'MFCardProgres' })

  const {
    id = 'card-progreso',
    title = 'Card Progreso',
    textAction = 'Progreso',
    txtPendiente = 'Pendiente',
    txtValidando = 'Validando',
    txtCompleto = 'Completo',
    txtRechazado = 'Rechazo',
    body,    
    status,
    lastItem,
    onChange,
    monocromatico,
    kindTask
  } = props
  
  
  const [backdropOn, setbackdropOn] = useState(false)

  const stylesTitle = css`
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5 !important;
    letter-spacing: 0.15px !important;
    color: ${monocromatico ? '#282828!important' : '#000'} !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  `

  const linkAction =css`
    color: #91B64B !important;
    font-weight: bold;
  `
  const fontStatus =css`
    text-align: center;
    align-items: center;
    color: #3F6377;
    width: 100%;
    @media(min-width: 350px ){
       font-size: 10px;
    }
    @media(min-width: 500px ){
        font-size: 14px;
    }
  `
  const lineFirst = css`
    min-width: 3.5rem;
    max-width: 3.5rem;
    width: 3.5 rem;
    margin-right: 2rem;
   
    height: 2.5rem;
    & hr {
      border-left: 25px line #d3d3d3;
      height: 98%;
      margin-top: 0;
      margin-bottom: 0;
    }
  `

  const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `

  const handleClick = (name) => {
    setbackdropOn(true)
    onChange({ value: name })
  }

  const textAlpha=()=>{
    let letra=textAction.toLowerCase()
    let mayuscula=letra[0].toUpperCase()
    let restoLetras=letra.slice(1)
    let nuevaletra=mayuscula+restoLetras
    return nuevaletra
  }
  
  const status_interno= kindTask === 'VerifyIdentityWithVideoConference' && status === 'inprogress'? 'Completed': status

  const verifications =(_kindTask,description)=>{
    let name=description.replace(/\s+/g, '')  
    let icon=''

    switch (_kindTask) {
      case 'VerifyEmail':          icon=IconEmail;     break;
      case 'VerifyBureau':         icon=IconCredit;      break;
      case 'VerifyAfi':            icon=IconBuro;      break;
      case 'VerifyMobileNumber':   icon=IconPhone;     break;      
      case 'VeridocIngresoAFI':    icon=IconBuro;      break;      
      case 'VerifyIdentityWithBiometrics':    icon=IconPerson;      break;
      case 'VerifySignature':    icon=IconBuro;      break;   
      case 'VerifyIdentityWithVideoConference':    icon=IconPerson;      break;
      case `VeriRefePersonal_${name}`:     icon=IconPerson;      break;
      case 'VeriDomicilio':    icon=IconPerson;      break;
      case 'VeriEmpleo':    icon=IconPerson;      break;                    
      case 'VeriPresencial':    icon=IconPerson;      break;
      default:  icon=IconPerson;      break;    
    }

    return ( 
            <div>              
              <img src={icon}  alt={`img-${kindTask}`} rel="nofollow noopener noreferrer"/>             
            </div>
            )

  }

  const estatusIcon =(status_interno)=>{
    let icon=''
    let text=''

    switch (status_interno) {
      case 'completed':          icon=IconCompleted; text=txtCompleto;    break;
      case 'inprogress':         icon=IconProgress;   text=txtValidando;   break;
      case 'error':            icon=IconError;    text=txtRechazado;  break;
      case 'new': default:   icon=IconPending;    text=txtPendiente;  break;            
    }

    return ( 
            <div>
              <img src={icon} alt={`img-${status_interno}`} rel="nofollow noopener noreferrer"/>
              <br/>
              <div css={fontStatus}>{text}</div>            
            </div>
            )
  }

  const name_=body.replace(/\s+/g, '')
  
  const cardTube = (    
    <Grid container spacing={1}>
      <Grid item xs={2}>
          {verifications(kindTask,body)} 
      </Grid>

      <Grid item xs={8} >
        <Typography css={stylesTitle}>{title} &nbsp;&nbsp;
            <Tooltip title={body} placement="top" arrow enterTouchDelay={0}>
                <InfoOutlined />
            </Tooltip>
        </Typography>
        <Typography css={stylesTitle}>
          {textAction !== '' &&  
                          (status_interno === 'new' || status_interno === 'needscorrection' || status_interno === 'inprogress' ) ? 
                            (  
                              (kindTask ===`VeriRefePersonal_${name_}`  )?(<span style={{color:'#f96828'}}>{body}</span>)
                              :(
                                <a 
                                    href={`javascript:void(${id})`}  
                                    rel="nofollow noopener noreferrer"
                                    onClick={() => handleClick(`${textAction}`)}
                                    css={linkAction}
                                >                                          
                                  {textAlpha()}&nbsp;<ArrowForwardIcon/>
                                </a>
                              ) 
                            ) 
                            :(kindTask ===`VeriRefePersonal_${name_}`  )?(<span style={{color:'#f96828'}}>{body}</span>):null
          }
        </Typography>
      </Grid>

      <Grid item xs={2} css={css`background-color:#F6F6F6;`}>
        {estatusIcon(status)}        
      </Grid>

    </Grid>
  )

  return (
    <Fragment>
    <Paper elevation={3} >
        {cardTube}
    </Paper>
    <Box display='flex' justifyContent='center' css={!lastItem && lineFirst}>
          {!lastItem ? <hr /> : <div>&#32;</div>}
    </Box> 
    <Backdrop css={bDrop} open={backdropOn} >
        <CircularProgress color="primary" />
    </Backdrop>
    </Fragment>    
  )
}

CardProgres.propTypes = {
  id: PropTypes.string,
  kindTask: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.node,
  textAction: PropTypes.string,
  status: PropTypes.oneOf(['completed', 'needscorrection', 'inprogress', 'new', 'error']),
  lastItem: PropTypes.bool,
  onChange: PropTypes.func,
  monocromatico: PropTypes.bool,
  txtPendiente : PropTypes.string,
  txtValidando : PropTypes.string,
  txtCompleto : PropTypes.string,
  txtRechazado : PropTypes.string
}



export default CardProgres
